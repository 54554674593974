/**
 * Created by TapFun on 2022/03/04.
 */
import qs from 'qs';
import request from '@/common/request'
import {SERVER_HOST} from "../common/portConfig";

//所属公司列表分页
export const reqGetCompanyPage = params => {
    return request.get(`/company/page`, {
        params: params
    })
};
// 根据id查询公司信息
export const reqGetInfoById = params => {
    return request.get(`/company/queryById`, {
        params: params
    })
};
//编辑公司信息
export const reqEditCompany = params => {
    return request.post(`/company/edit`, qs.stringify(params))
};
//新增公司
export const reqAddCompany = params => {
    return request.post(`/company/add`, qs.stringify(params))
};
//删除公司
export const reqDelCompany = params => {
    return request.get(`/company/del`, {
        params: params
    })
};
// 资产清单
export const reqAsset = params =>{
    return request.get(`asset/getAsset`,{
        params:params
    })
}
// 资产清单分页查询
export const reqAssetPage = params =>{
    return request.post(`asset/page`, params)
}
// 资产清单编辑
export const reqEdit = params =>{
    return request.post(`asset/edit`,params)
}
// 资产清单添加
export const reqAdd = params =>{
    return request.post(`asset/add`,params)
}
// 资产清单删除
export const reqDel = params =>{
    return request.post(`asset/del`,params)
}
// 资产清单通过id查询
export const reqGetAssetById = params =>{
    return request.get(`asset/getAssetById`,{
        params: params
    })
}
// 资产分类
export const reqGetClassify = params =>{
    return request.get(`asset/classify`,{
        params: params
    })
}
// 资产导入
export const reqVerifyAndSaveAssets = `${SERVER_HOST}/asset/verifyAndSave`;

// 所属公司
export const reqGetCompany = params =>{
    return request.get(`asset/company`,{
        params: params
    })
}
// 资产标签打印任务添加
export const reqAddPrintHistory = params =>{
    return request.post(`printHistory/add`,params)
}
//获取标签模板
export const reqGetTabTemplate = params => {
    return request.get(`/template/list`, {
        params: params
    })
};
//选择标签模板
export const reqChooseTemplate = params => {
    return request.get(`/template/choose`, {
        params: params
    })
};
//查询可用的标签模板
export const reqAvailTemplate = params => {
    return request.get(`/template/avail`, {
        params: params
    })
};
//设置默认公司
export const reqSetDefaultCompany = params => {
    return request.get(`/company/set`, {
        params: params
    })
};
//领用单列表分页
export const reqGetReceivingPage = params => {
    return request.get(`/receiving/page`, {
        params: params
    })
};
//根据领用单id获取资产信息
export const reqGetAssetInfoByReceivingId = params => {
    return request.get(`/receiving/list`, {
        params: params
    })
};
//派发单单列表分页
export const reqGetDistributePage = params => {
    return request.get(`/distribute/page`, {
        params: params
    })
};
//根据派发单id获取资产信息
export const reqGetInfoByDistributeId = params => {
    return request.get(`/distribute/list`, {
        params: params
    })
};
//调拨单列表分页
export const reqGetAllocatePage = params => {
    return request.get(`/allocate/page`, {
        params: params
    })
};
//根据调拨单id获取资产信息
export const reqGetInfoByAllocateId = params => {
    return request.get(`/allocate/list`, {
        params: params
    })
};
//归还单列表分页
export const reqGetGivebackPage = params => {
    return request.get(`/giveback/page`, {
        params: params
    })
};
//根据归还单id获取资产信息
export const reqGetInfoByGivebackId = params => {
    return request.get(`/giveback/list`, {
        params: params
    })
};
// 所在位置
export const reqGetLocation = params =>{
    return request.get(`location/assetLocation`,{
        params: params
    })
}
// 资产位置分页
export const reqGetLocationList = params =>{
    return request.get(`location/list`,{
        params: params
    })
}
// 所在位置ById
export const reqLocationById = params =>{
    return request.get(`location/locationById`,{
        params: params
    })
}
// 编辑资产位置
export const reqEditLocation = params =>{
    return request.post(`location/edit`,params)
}
// 编辑资产位置
export const reqDelLocation = params =>{
    return request.post(`location/del`,params)
}

// 获取资产分类树列表
export const reqGetClassifyTree = params =>{
    return request.get(`/classify/tree`,{
        params: params
    })
}
// 根据条件查询资产分类列表
export const reqGetClassifyByFilters = params =>{
    return request.get(`/classify/byFilters`,{
        params: params
    })
}
// 根据id查询资产分类及其下级分类列表
export const reqGetChildListById = params =>{
    return request.get(`/classify/childListById`,{
        params: params
    })
}
// 资产分类新增
export const reqAddClassify = params =>{
    return request.post(`/classify/add`,params)
}
// 资产分类编辑
export const reqEditClassify = params =>{
    return request.post(`/classify/edit`,params)
}
// 资产分类删除
export const reqDelClassify = params => {
    return request.get(`/classify/del`,{
        params: params
    })
};
//退库单列表分页
export const reqGetReturnPage = params => {
    return request.get(`/return/page`, {
        params: params
    })
};
//根据退库单id获取资产信息
export const reqGetInfoByReturnId = params => {
    return request.get(`/return/list`, {
        params: params
    })
};
//根据资产id获取图片、附件
export const reqGetAttachmentByAssetId = params => {
    return request.get(`/attachment/list`, {
        params: params
    })
};
//借用单列表分页
export const reqGetBorrowPage = params => {
    return request.get(`/borrow/page`, {
        params: params
    })
};
//根据借用单id获取资产信息
export const reqGetInfoByBorrowId = params => {
    return request.get(`/borrow/list`, {
        params: params
    })
};
// 资产分类导入
export const reqImportClassify = `${SERVER_HOST}/classify/import`;
// 资产分类导入
export const reqVerifyAndSaveClassify = `${SERVER_HOST}/classify/verifyAndSave`;
// 编辑资产位置
export const reqAddLocation = params =>{
    return request.post(`location/add`,params)
}
// 用户信息
export const reqUser = params =>{
    return request.get(`asset/user`,{
        params: params
    })
}
// 根据id查询资产分类及其下级分类列表
export const reqGetLocationChildListById = params =>{
    return request.get(`/location/childListById`,{
        params: params
    })
}
// 根据类型查询审批流程信息
export const reqGetProcessByType = params =>{
    return request.get(`/process/byType`,{
        params: params
    })
}
// 审批流程编辑
export const reqEditProcess = params =>{
    return request.post(`/process/edit`,params)
}
// 审批流程启用、禁用
export const reqToggleProcessStatus = params =>{
    return request.post(`/process/toggleStatus`,params)
}
//处置单列表分页
export const reqGetDisposePage = params => {
    return request.get(`/dispose/page`, {
        params: params
    })
};
//根据处置单id获取资产信息
export const reqGetInfoByDisposeId = params => {
    return request.get(`/dispose/list`, {
        params: params
    })
};
//维修单列表分页
export const reqGetRepairPage = params => {
    return request.get(`/repair/page`, {
        params: params
    })
};
//根据维修单id获取资产信息
export const reqGetInfoByRepairId = params => {
    return request.get(`/repair/list`, {
        params: params
    })
};
//保养单列表分页
export const reqGetMaintainPage = params => {
    return request.get(`/maintain/page`, {
        params: params
    })
};
//根据保养单id获取资产信息
export const reqGetInfoByMaintainId = params => {
    return request.get(`/maintain/list`, {
        params: params
    })
};
//盘点单列表分页
export const reqGetInventoryPage = params => {
    return request.get(`/inventory/page`, {
        params: params
    })
};
//根据盘点单id获取资产信息
export const reqGetInfoByInventoryId = params => {
    return request.get(`/inventory/list`, {
        params: params
    })
};
//统计
export const reqGetDashboard = params => {
    return request.get(`/asset/dashboard`, {
        params: params
    })
};
//使用记录列表分页
export const reqGetAthPage = params => {
    return request.get(`/assetTransferHistory/page`, {
        params: params
    })
};
//查看资产使用记录
export const reqViewAth = params => {
    return request.get(`/assetTransferHistory/view`, {
        params: params
    })
};
//获取可盘点的资产
export const reqGetAssetList = params => {
    return request.get(`/inventory/getAssetList`, {
        params: params
    })
};
//盘点单新增
export const reqAddInventory = params => {
    return request.post(`/inventory/add`, params)
};
//根据多个资产id获取资产信息
export const reqGetAssetByIds = params => {
    return request.get(`/inventory/getAssetByIds`, {
        params: params
    })
};
//删除盘点单
export const reqDelInventory = params => {
    return request.get(`/inventory/del`, {
        params: params
    })
};
//根据盘点单id获取盘点单原有资产和可盘点的资产
export const reqGetEditInventoryAssetList = params => {
    return request.get(`/inventory/getEditInventoryAssetList`, {
        params: params
    })
};
//获取盘点单已有的资产
export const reqGetAssetByInventoryId = params => {
    return request.get(`/inventory/getAssetByInventoryId`, {
        params: params
    })
};
//编辑盘点单
export const reqEditInventory = params => {
    return request.post(`/inventory/edit`, params)
};

//当前资产统计
export const statistic = params => {
    return request.get(`/asset/ddDashboard`, {
        params: params
    })
};

//资产每月分布
export const mychartByYear = params => {
    return request.get(`/asset/mychartByYear`, {
        params: params
    })
};

//根据盘点单id获取资产分页
export const reqGetAssetPageByInventoryId = params => {
    return request.get(`/inventory/getAssetPageByInventoryId`, {
        params: params
    })
};

export const getUserListByRoleName = params => {
    return request.get(`/user/byRoleName`, {
        params: params
    })
};

export const getUserByDeptId = params=>{
    return request.get(`/user/getByDeptId`,{
        params:params
    })
}

// 查询部门树型选项列表
export const reqGetDeptTreeOptions = params =>{
    return request.get(`department/treeOptions`,{
        params:params
    })
}
